<template>
  <footer :class="['footer',isFooter?'footerOne':'']">
    <div class="centerPlate">
      <p class="contactWay">
        公司：小链科技<span class="line" /><i />电话：4001999369&nbsp;
        邮箱：service@qljc.com.cn
      </p>
      <p class="contactWay">
        地址：广东省深圳市福田区园岭街道华林社区八卦路31号八卦岭工业区众鑫科技大厦9层951A
      </p>
      <p>
        ©2022 小链科技 版权所有<a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          rel="noopener noreferrer"
        >粤ICP备2022121982号-1</a>
      </p>
      <div class="imageBox">
        <img
          v-if="!isFooter"
          class="footerImg"
          src="@/assets/img/footerImg.png"
          alt="bg"
        >
        <img
          v-else
          class="footerImg"
          src="@/assets/img/footerImg1.png"
          alt="bg"
        >
        <span v-if="isFooter">信息安全管理体系认证</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      isFooter: true
    }
  },
  watch: {
    '$route.path': {
      handler(newVal) {
        this.isFooter = newVal === '/fourGoldenTreasures/index'
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  background-color: #fff;
  height: 139px;
  display: flex;
  align-items: center;
  justify-content: center;
  .centerPlate {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #666666;
    .contactWay {
      display: flex;
      align-items: center;
      margin: 8px 0;
    }
    .imageBox{
      display: flex;
      align-items: center;
      margin-top: 8px;
    }
    .footerImg{
      width: 168px;
      height: 33px;
      object-fit: contain;
    }
  }
  .line {
    width: 1px;
    border-right: 1px solid #ddd;
    display: inline-block;
    height: 14px;
    margin: 0 8px;
    vertical-align: middle;
  }
}
.footerOne{
  background-color: #232323;
  .centerPlate{
    color: rgba(255,255,255,.7);
    a{
      color: rgba(255,255,255,.7);
    }
    .footerImg{
      width: 40px;
      height: 33px;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
  .line{
    border-right: 1px solid rgba(255,255,255,.7);
  }
}
</style>
