<template>
  <div class="rawGoldChainPageBox">
    <!-- 介绍内容 -->
    <div class="introduceContent">
      <div class="content">
        <!-- 介绍内容 -->
        <div class="introduce">
          <h3>什么是生金链</h3>
          <p>
            小链科技供应链旗下产品，买家通过小链科技商城支付20%锁定货物，再融资80%支付给卖家，仓单过户给买家且同时质押给银行。
          </p>
          <!-- <el-popover
            title="联系我们"
            width="140"
            trigger="hover"
            content="业务咨询，您可以直接拨打400-199-9369"
          >
            <div slot="reference" class="consultImmediately">
              立即咨询
            </div>
          </el-popover> -->
          <div class="consultImmediately" @click="gotoLink">
            立即咨询
          </div>
        </div>
        <!-- 图片 -->
        <img
          src="@/assets/fourGoldenTreasuresImg/image/banner1.png"
          alt="banner"
        >
      </div>
    </div>
    <!-- 操作指南 -->
    <div class="instructions">
      <h3 class="title">
        操作指南
      </h3>
      <p class="describe">
        适合正在购买小链科技商城货物，但是存在资金不足的问题
      </p>
      <!-- 流程 -->
      <div class="flowBox">
        <div class="imageBox">
          <img
            src="@/assets/fourGoldenTreasuresImg/icon/icon18.png"
            alt="icon"
          >
          <h4>业务开通</h4>
          <span>在线申请并开通“生金链”</span>
        </div>
        <img
          class="line"
          src="@/assets/fourGoldenTreasuresImg/icon/line.png"
          alt="icon"
        >
        <div class="imageBox">
          <img
            src="@/assets/fourGoldenTreasuresImg/icon/icon12.png"
            alt="icon"
          >
          <h4>额度授信</h4>
          <span>申请额度，获得授信</span>
        </div>
        <img
          class="line"
          src="@/assets/fourGoldenTreasuresImg/icon/line.png"
          alt="icon"
        >
        <div class="imageBox">
          <img
            src="@/assets/fourGoldenTreasuresImg/icon/icon13.png"
            alt="icon"
          >
          <h4>商城买货&订单融资</h4>
          <span>买家20%+融资80%</span>
        </div>
        <img
          class="line"
          src="@/assets/fourGoldenTreasuresImg/icon/line.png"
          alt="icon"
        >
        <div class="imageBox">
          <img
            src="@/assets/fourGoldenTreasuresImg/icon/icon14.png"
            alt="icon"
          >
          <h4>仓单过户</h4>
          <span>仓单过户给买家并质押</span>
        </div>
        <img
          class="line"
          src="@/assets/fourGoldenTreasuresImg/icon/line.png"
          alt="icon"
        >
        <div class="imageBox">
          <img
            src="@/assets/fourGoldenTreasuresImg/icon/icon15.png"
            alt="icon"
          >
          <h4>还贷解押</h4>
          <span>自主还贷并解押货物</span>
        </div>
      </div>
    </div>
    <!-- 产品特性 -->
    <div class="productFeature">
      <h3 class="title">
        产品特性
      </h3>
      <!-- 内容 -->
      <div class="content">
        <div class="left">
          <h3>
            <img
              src="@/assets/fourGoldenTreasuresImg/icon/icon11.png"
              alt=""
            >安全高效
          </h3>
          <p>监管仓源头把控，优化融资流程，提升资金周转率</p>
          <h3>
            <img
              src="@/assets/fourGoldenTreasuresImg/icon/icon23.png"
              alt=""
            >线上化
          </h3>
          <p>实名认证、合同签署、在线融资等全流程线上化</p>
          <h3>
            <img
              src="@/assets/fourGoldenTreasuresImg/icon/icon26.png"
              alt=""
            >低费用
          </h3>
          <p>依托核心企业，低息还款，无压力</p>
        </div>
        <div class="center">
          <img
            class="lowFigure"
            src="@/assets/fourGoldenTreasuresImg/image/motionGraph3.png"
            alt="动图"
          >
          <img
            class="motionGraph1"
            src="@/assets/fourGoldenTreasuresImg/image/motionGraph4.png"
            alt="动图"
          >
          <img
            class="motionGraph2"
            src="@/assets/fourGoldenTreasuresImg/image/motionGraph5.png"
            alt="动图"
          >
          <img
            class="motionGraph3"
            src="@/assets/fourGoldenTreasuresImg/image/motionGraph6.png"
            alt="动图"
          >
          <img
            class="motionGraph4"
            src="@/assets/fourGoldenTreasuresImg/image/motionGraph7.png"
            alt="动图"
          >
          <img
            class="motionGraph5"
            src="@/assets/fourGoldenTreasuresImg/image/motionGraph8.png"
            alt="动图"
          >
          <img
            class="motionGraph6"
            src="@/assets/fourGoldenTreasuresImg/image/motionGraph9.png"
            alt="动图"
          >
        </div>
        <div class="right">
          <h3>
            <img
              src="@/assets/fourGoldenTreasuresImg/icon/icon3.png"
              alt=""
            >极速申请
          </h3>
          <p>企业成立满1年，低门槛，快速审核</p>
          <h3>
            <img
              src="@/assets/fourGoldenTreasuresImg/icon/icon4.png"
              alt=""
            >秒速放款
          </h3>
          <p>流程自动审批，授信到放款最快1小时</p>
          <h3>
            <img
              src="@/assets/fourGoldenTreasuresImg/icon/icon5.png"
              alt=""
            >高额度
          </h3>
          <p>额度高达300万，全方位满足您的用款需求</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    gotoLink() {
      window.open('https://my-i-hub.toocle.com/index.php', '_blank')
    }
  }
}
</script>

<style lang="scss">
.rawGoldChainPageBox {
  background-color: #fff;
  .productFeature {
    width: 120rem;
    padding: 8rem 0;
    margin: auto;
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left,
      .right {
        flex: 0 0 20rem;
        p {
          color: #666;
          font-size: 1.6rem;
          font-weight: 400;
          margin-bottom: 4.2rem;
        }
        p:last-of-type {
          margin-bottom: 0;
        }
        h3 {
          color: #333;
          font-size: 2rem;
          display: flex;
          align-items: center;
          font-weight: 500;
          padding-bottom: 8px;
          img {
            height: 2.4rem;
            width: 2.4rem;
            margin-right: 0.4rem;
          }
        }
      }
      .center {
        flex: 0 0 59.6rem;
        height: 28.6rem;
        position: relative;
        img {
          height: 7.4rem;
          width: 8.1rem;
        }
        .motionGraph2 {
          position: absolute;
          bottom: 4.7rem;
          left: 0;
          height: 7.4rem !important;
          width: 8.1rem !important;
          animation: bounce-down 3s linear infinite;
        }
        .motionGraph1 {
          position: absolute;
          top: 3.8rem;
          left: 5rem;
          animation: bounce-down 3s linear infinite;
        }
        .motionGraph3 {
          position: absolute;
          bottom: 3.8rem;
          left: 18rem;
          animation: bounce-down 3s linear infinite;
        }
        .motionGraph4 {
          position: absolute;
          bottom: 0;
          right: 14rem;
          animation: bounce-down 3s linear infinite;
        }
        .motionGraph5 {
          position: absolute;
          bottom: 6.6rem;
          right: 0;
          animation: bounce-down 3s linear infinite;
        }
        .motionGraph6 {
          position: absolute;
          top: 4.6rem;
          right: 5rem;
          animation: bounce-down 3s linear infinite;
        }
        .lowFigure {
          width: 100%;
          height: 100%;
        }
        @keyframes bounce-down {
          25% {
            -webkit-transform: translateY(-4px);
          }
          50%,
          100% {
            -webkit-transform: translateY(0);
          }
          75% {
            -webkit-transform: translateY(4px);
          }
        }
      }
    }

    .title {
      color: #000;
      font-size: 2.8rem;
      font-weight: 500;
      padding-bottom: 8rem;
      text-align: center;
    }
  }
  .instructions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10rem 0;
    background-color: #fafafa;
    .flowBox {
      width: 120rem;
      display: flex;
      justify-content: space-between;
      .imageBox {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 0 0 auto;
        span {
          color: #666;
          font-size: 1.6rem;
          font-weight: 400;
        }
        h4 {
          margin: 1.6rem 0 0.4rem;
          color: #333;
          font-size: 2rem;
          font-weight: 500;
        }
        img {
          height: 7.2rem;
          width: 7.2rem;
        }
      }
      .line {
        margin-top: 3.6rem;
        width: 10.8rem;
        height: 1px;
      }
    }
    .describe {
      font-size: 2rem;
      color: #666;
      font-weight: 400;
      padding-bottom: 8rem;
    }
    .title {
      font-weight: 500;
      font-size: 2.8rem;
      color: #000;
      line-height: 4rem;
      padding-bottom: 0.8rem;
    }
  }
  .introduceContent {
    width: 120rem;
    padding: 8rem 0;
    margin: auto;
    .content {
      padding: 2.4rem 2.4rem 2.4rem 5.6rem;
      background-color: rgba(255, 126, 6, 0.04);
      display: flex;
      justify-content: space-between;
      .introduce {
        width: 51.6rem;
        .consultImmediately {
          margin-top: 4rem;
          display: inline-block;
          text-align: center;
          font-size: 1.6rem;
          border-radius: 4px;
          background-color: #ff8f27;
          color: #fff;
          width: 12.8rem;
          height: 3.8rem;
          line-height: 3.8rem;
          cursor: pointer;
        }
        p {
          color: #333;
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
        h3 {
          margin: 4rem 0 1.6rem;
          color: #333;
          font-size: 2.8rem;
          font-weight: 500;
        }
      }
      img {
        height: 30rem;
        width: 53.2rem;
      }
    }
  }
}
</style>
