<template>
  <div class="rawGoldChain">
    <!-- 介绍内容 -->
    <div class="introduceContent">
      <div class="content">
        <!-- 介绍内容 -->
        <div class="introduce">
          <h3>什么是采金链</h3>
          <p>小链科技供应链旗下产品，买方支付一定比例的保证金给小链科技供应链平台上的供应链公司，由供应链向上游卖方进行代采服务，买方以打款赎货的模式进行提货。</p>
          <span @click="applyNow">立即申请</span>
        </div>
        <!-- 图片 -->
        <img
          src="@/assets/fourGoldenTreasuresImg/image/banner2.png"
          alt="banner"
        >
      </div>
    </div>
    <!-- 操作指南 -->
    <div class="instructions">
      <h3 class="title">
        操作指南
      </h3>
      <p class="describe">
        适合有钢材需求，但存在资金不足问题的中小钢贸企业
      </p>
      <!-- 流程 -->
      <div class="flowBox">
        <div class="imageBox">
          <img src="@/assets/fourGoldenTreasuresImg/icon/icon18.png" alt="icon">
          <h4>业务开通</h4>
          <span>在线申请并开通"采金链"</span>
        </div>
        <img class="line" src="@/assets/fourGoldenTreasuresImg/icon/line.png" alt="icon">
        <div class="imageBox">
          <img src="@/assets/fourGoldenTreasuresImg/icon/icon12.png" alt="icon">
          <h4>采购计划</h4>
          <span>提交钢材需求表</span>
        </div>
        <img class="line" src="@/assets/fourGoldenTreasuresImg/icon/line.png" alt="icon">
        <div class="imageBox">
          <img src="@/assets/fourGoldenTreasuresImg/icon/icon16.png" alt="icon">
          <h4>支付保证金</h4>
          <span>支付一定比例合同保证金</span>
        </div>
        <img class="line" src="@/assets/fourGoldenTreasuresImg/icon/line.png" alt="icon">
        <div class="imageBox">
          <img src="@/assets/fourGoldenTreasuresImg/icon/icon13.png" alt="icon">
          <h4>垫资代采</h4>
          <span>向钢厂代采，钢厂交货</span>
        </div>
        <img class="line" src="@/assets/fourGoldenTreasuresImg/icon/line.png" alt="icon">
        <div class="imageBox">
          <img src="@/assets/fourGoldenTreasuresImg/icon/icon15.png" alt="icon">
          <h4>打款提货</h4>
          <span>自主打款并完成提货</span>
        </div>
      </div>
    </div>
    <!-- 产品特性 -->
    <div class="productFeature">
      <h3 class="title">
        核心功能
      </h3>
      <div class="content">
        <div class="left">
          <h3><img src="@/assets/fourGoldenTreasuresImg/icon/icon6.png" alt="">合同结算管理</h3>
          <p>对账管理、付款管理、发票管理</p>
          <h3><img src="@/assets/fourGoldenTreasuresImg/icon/icon7.png" alt="">采购提货管理</h3>
          <p>发起提货申请、支付货款和代采费、打印提货单</p>
          <h3><img src="@/assets/fourGoldenTreasuresImg/icon/icon8.png" alt="">采购合同管理</h3>
          <p>数字化合同集成、电子签章、自动模板套用</p>
        </div>
        <div class="center">
          <img class="lowFigure" src="@/assets/fourGoldenTreasuresImg/image/motionGraph2.png" alt="动图">
          <img class="motionGraph1" src="@/assets/fourGoldenTreasuresImg/image/motionGraph1.png" alt="动图">
        </div>
        <div class="right">
          <h3><img src="@/assets/fourGoldenTreasuresImg/icon/icon9.png" alt="">额度授信管理</h3>
          <p>申请额度、额度批复、额度生效、额度冻结、额度解冻、额度终止、额度恢复</p>
          <h3><img src="@/assets/fourGoldenTreasuresImg/icon/icon10.png" alt="">采购需求管理</h3>
          <p>采购需求形成、提交采购计划、支付保证金</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    applyNow() {
      // let projectUrl = ''
      // switch (true) {
      //   case this.$projectUrl.indexOf('369tecgroup') >= 0:
      //     projectUrl = 'https://b2b.369tecgroup.com/#/'
      //     break
      //   default:
      //     projectUrl = this.$projectUrl
      //     break
      // }
      window.open(`${this.$agentUrl}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.rawGoldChain {
  background-color: #fff;
  .productFeature{
    width: 120rem;
    padding: 8rem 0;
    margin: auto;
    .content{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left,.right{
        flex: 0 0 20rem;
        p{
          color: #666;
          font-size: 1.6rem;
          font-weight: 400;
          margin-bottom: 4.2rem;
        }
        p:last-of-type{
          margin-bottom: 0;
        }
        h3{
          color: #333;
          font-size: 2rem;
          display: flex;
          align-items: center;
          font-weight: 500;
          padding-bottom: 8px;
          img{
            height: 2.4rem;
            width: 2.4rem;
            margin-right: .4rem;
          }
        }
      }
      .center{
        flex: 0 0 56.6rem;
        height: 28.3rem;
        position: relative;
        .motionGraph1{
          position: absolute;
          bottom: 3rem;
          left: 1.8rem;
          width: 52.6rem;
          height: 17rem;
          animation: bounce-down 4s linear infinite;
        }
        .lowFigure{
          width: 100%;
          height: 100%;
        }
        @keyframes bounce-down {
          25% {
            -webkit-transform: translateY(-20px);
          }
          50%,
          100% {
            -webkit-transform: translateY(0);
          }
          75% {
            -webkit-transform: translateY(20px);
          }
        }
      }
    }

    .title{
      color: #000;
      font-size: 2.8rem;
      font-weight: 500;
      padding-bottom: 8rem;
      text-align: center;
    }
  }
  .instructions{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10rem 0;
    background-color: #FAFAFA;
    .flowBox{
      width: 120rem;
      display: flex;
      justify-content: space-between;
      .imageBox{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 0 0 auto;
        span{
          color: #666;
          font-size: 1.6rem;
          font-weight: 400;
        }
        h4{
          margin: 1.6rem 0 .4rem;
          color: #333;
          font-size: 2rem;
          font-weight: 500;
        }
        img{
          height: 7.2rem;
          width: 7.2rem;
        }
      }
      .line{
        margin-top: 3.6rem;
        width: 10.8rem;
        height: 1px;
      }
    }
    .describe{
      font-size: 2rem;
      color: #666;
      font-weight: 400;
      padding-bottom: 8rem;
    }
    .title{
      font-weight: 500;
      font-size: 2.8rem;
      color: #000;
      line-height: 4rem;
      padding-bottom: .8rem;
    }
  }
  .introduceContent {
    width: 120rem;
    padding: 8rem 0;
    margin: auto;
    .content {
      padding: 2.4rem 2.4rem 2.4rem 5.6rem;
      background-color: rgba(255, 126, 6, 0.04);
      display: flex;
      justify-content: space-between;
      .introduce {
        width: 51.6rem;
        span{
          margin-top: 4rem;
          display: inline-block;
          text-align: center;
          font-size: 1.6rem;
          border-radius: 4px;
          background-color: #FF8F27;
          color: #fff;
          width: 12.8rem;
          height: 3.8rem;
          line-height: 3.8rem;
          cursor: pointer;
        }
        p{
          color: #333;
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
        h3 {
          margin: 4rem 0 1.6rem;
          color: #333;
          font-size: 2.8rem;
          font-weight: 500;
        }
      }
      img {
        height: 30rem;
        width: 53.2rem;
      }
    }
  }
}
</style>
