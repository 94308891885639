<template>
  <div class="rawGoldChainPageBox">
    <!-- 介绍内容 -->
    <div class="introduceContent">
      <div class="content">
        <!-- 介绍内容 -->
        <div class="introduce">
          <h3>什么是票金链</h3>
          <p>
            小链科技供应链旗下产品，由核心企业签发的供应链票据、商业承兑票据、银行承兑票据，可以通过小链科技供应链平台对接的资金机构进行融资。
          </p>
          <el-popover
            title="联系我们"
            width="140"
            trigger="hover"
            content="业务咨询，您可以直接拨打400-199-9369"
          >
            <div slot="reference" class="consultImmediately">
              立即咨询
            </div>
          </el-popover>
        </div>
        <!-- 图片 -->
        <img
          src="@/assets/fourGoldenTreasuresImg/image/banner3.png"
          alt="banner"
        >
      </div>
    </div>
    <!-- 操作指南 -->
    <div class="instructions">
      <h3 class="title">
        操作指南
      </h3>
      <p class="describe">
        适合产业链上中小微企业将基于核心企业的应收账款多级拆分、流转
      </p>
      <!-- 流程 -->
      <div class="flowBox">
        <img src="@/assets/fourGoldenTreasuresImg/image/flow1.png" alt="flow">
      </div>
    </div>
    <!-- 业务特点 -->
    <div class="businessCharacteristic">
      <h3 class="title">
        业务特点
      </h3>
      <div class="imageBox">
        <div class="left">
          <h3><img src="@/assets/fourGoldenTreasuresImg/icon/icon24.png" alt="icon">业务广度</h3>
          <p>覆盖主流承兑主体</p>
          <h3><img src="@/assets/fourGoldenTreasuresImg/icon/icon21.png" alt="icon">线上签约</h3>
          <p>与银行在线签署合约</p>
        </div>
        <div class="bannerBox">
          <img class="icon motionGraph1" src="@/assets/fourGoldenTreasuresImg/image/motionGraph12.png" alt="动图">
          <img class="icon motionGraph2" src="@/assets/fourGoldenTreasuresImg/image/motionGraph12.png" alt="动图">
          <img class="icon motionGraph3" src="@/assets/fourGoldenTreasuresImg/image/motionGraph12.png" alt="动图">
          <img class="icon motionGraph4" src="@/assets/fourGoldenTreasuresImg/image/motionGraph11.png" alt="动图">
          <img class="icon motionGraph5" src="@/assets/fourGoldenTreasuresImg/image/motionGraph11.png" alt="动图">
          <img class="icon motionGraph6" src="@/assets/fourGoldenTreasuresImg/image/motionGraph11.png" alt="动图">
          <img class="banner" src="@/assets/fourGoldenTreasuresImg/image/motionGraph10.png" alt="motionGraph">
        </div>
        <div class="right">
          <h3><img src="@/assets/fourGoldenTreasuresImg/icon/icon23.png" alt="icon">高度对接</h3>
          <p>内外系统快速对接</p>
          <h3><img src="@/assets/fourGoldenTreasuresImg/icon/icon22.png" alt="icon">快速放款</h3>
          <p>金融机构快速放款</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.rawGoldChainPageBox {
  background-color: #fff;
  .businessCharacteristic{
    padding: 8rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .imageBox{
      position: relative;
      width: 120rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .left,.right{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        h3{
          display: flex;
          align-items: center;
          color: #333333;
          font-size: 2rem;
          font-weight: 500;
          margin-bottom: 1rem;
          img{
            height: 2.4rem;
            width: 2.4rem;
            margin-right: .4rem;
          }
        }
        p{
          padding-bottom: 4.2rem;
          color: #666666;
          font-size: 1.6rem;
          font-weight: 400;
        }
        p:last-of-type{
          padding-bottom: 0;
        }
      }
      .right{
        right: 0;
      }
      .left{
        left: 0;
      }
      .bannerBox{
        position: relative;
        .icon{
          position: absolute;
          width: .76rem;
          height: 7rem;
        }
        .motionGraph1{
          top: 11.2rem;
          right: 27.9rem;
          animation: bounce-down 4s linear infinite;
        }
        .motionGraph2{
          top: 6.2rem;
          left: 31.8rem;
          animation: bounce-down 4s linear infinite;
        }
        .motionGraph3{
          bottom: 17rem;
          right: 17.9rem;
          animation: bounce-down 4s linear infinite;
        }
        .motionGraph4{
          bottom: 15.6rem;
          left: 22.7rem;
          animation: bounce-down 4s linear infinite;
        }
        .motionGraph5{
          bottom: 6.2rem;
          right: 28.6rem;
          animation: bounce-down 4s linear infinite;
        }
        .motionGraph6{
          bottom: 8rem;
          left: 34rem;
          animation: bounce-down 4s linear infinite;
        }
        @keyframes bounce-down {
          25% {
            -webkit-transform: translateY(-20px);
          }
          50%,
          100% {
            -webkit-transform: translateY(0);
          }
          75% {
            -webkit-transform: translateY(20px);
          }
        }
      }
      .banner{
        display: block;
        width: 101.8rem;
        height: 42.7rem;
      }
    }
    .title{
      color: #000;
      font-size: 2.8rem;
      font-weight: 500;
      padding-bottom: 8rem;
    }
  }
  .instructions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10rem 0 6.4rem;
    background-color: #fafafa;
    .flowBox {
      width: 120rem;
      img{
        width: 100%;
        display: block;
      }
    }
    .describe {
      font-size: 2rem;
      color: #666;
      font-weight: 400;
      padding-bottom: 8rem;
    }
    .title {
      font-weight: 500;
      font-size: 2.8rem;
      color: #000;
      line-height: 4rem;
      padding-bottom: 0.8rem;
    }
  }
  .introduceContent {
    width: 120rem;
    padding: 8rem 0;
    margin: auto;
    .content {
      padding: 2.4rem 2.4rem 2.4rem 5.6rem;
      background-color: rgba(255, 126, 6, 0.04);
      display: flex;
      justify-content: space-between;
      .introduce {
        width: 51.6rem;
        .consultImmediately {
          margin-top: 4rem;
          display: inline-block;
          text-align: center;
          font-size: 1.6rem;
          border-radius: 4px;
          background-color: #ff8f27;
          color: #fff;
          width: 12.8rem;
          height: 3.8rem;
          line-height: 3.8rem;
          cursor: pointer;
        }
        p {
          color: #333;
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
        h3 {
          margin: 4rem 0 1.6rem;
          color: #333;
          font-size: 2.8rem;
          font-weight: 500;
        }
      }
      img {
        height: 30rem;
        width: 53.2rem;
      }
    }
  }
}
</style>
